import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "C:/xampp/htdocs/uniswap-org-v1/src/layouts/index.js";
import BG from '../components/bg';
import styled from 'styled-components';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Title = makeShortcode("Title");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <BG mdxType="BG" />
    <div style={{
      maxWidth: '650px',
      margin: '0px auto 10rem auto',
      padding: '0 1rem'
    }}>
      <Title mdxType="Title">Rissotto protocol Disclaimer
      </Title>
      <p>{`Rissotto is a decentralized peer-to-peer protocol that people can use to create liquidity and trade BEP-20 tokens. Your use of the Rissotto protocol involves various risks, including, but not limited to, losses while digital assets are being supplied to the Rissotto protocol and losses due to the fluctuation of prices of tokens in a trading pair or liquidity pool. Before using the Rissotto protocol, you should review the relevant documentation to make sure you understand how the Rissotto protocol works. Additionally, just as you can access email email protocols such as SMTP through multiple email clients, you can access the Rissotto protocol through dozens of web or mobile interfaces. You are responsible for doing your own diligence on those interfaces to understand the fees and risks they present.`}</p>
      <p>{`AS DESCRIBED IN THE RISSOTTO PROTOCOL LICENSES, THE RISSOTTO PROTOCOL IS PROVIDED `}{`”`}{`AS IS`}{`”`}{`, AT YOUR OWN RISK, AND WITHOUT WARRANTIES OF ANY KIND. `}{`”`}{`Rissotto`}{`”`}{` ( `}{`”`}<strong parentName="p">{`Rissotto`}</strong>{`”`}{` ) developed much of the initial code for the Rissotto protocol, it does not provide, own, or control the Rissotto protocol, which is run by smart contracts deployed on the BSC blockchain. No developer or entity involved in creating the Rissotto protocol will be liable for any claims or damages whatsoever associated with your use, inability to use, or your interaction with other users of, the Rissotto protocol, including any direct, indirect, incidental, special, exemplary, punitive or consequential damages, or loss of profits, cryptocurrencies, tokens, or anything else of value.`}</p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      